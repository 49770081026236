'use client';

import { useEffect, useRef } from 'react';
import analytics from '@/services/analytics';
import { CmsFleetCategory } from '@/services/cms/models/CmsFleetCategory';

export const FleetCategoryAnalytics = ({ fleetCategory }: { fleetCategory?: CmsFleetCategory }) => {
    const hasTriggeredEvent = useRef(false);
    useEffect(() => {
        if (!hasTriggeredEvent.current && fleetCategory?.code) {
            analytics.plugins.ecommerce.trackEcommerce('view_item', {
                items: [
                    {
                        item_id: fleetCategory.code,
                        item_name: fleetCategory.name,
                        item_brand: fleetCategory.fleetTypeSlug === 'motorhome' ? 'star' : 'jucy',
                        item_category: fleetCategory.fleetTypeSlug,
                    },
                ],
            });
            hasTriggeredEvent.current = true;
        }
    }, [fleetCategory?.code, fleetCategory?.name, fleetCategory?.fleetTypeSlug]);

    return null;
};
