'use client';

import React, { useCallback } from 'react';
import { sleep } from '@jucy-ui/common';
import { SearchForm, SearchFormHiddenFields, SearchFormProps, SearchFormValues, SearchFormVariants, searchFormValuesToData } from '@jucy-ui/components/SearchForm';
import { useSite } from '@/hooks/useSite';
import analytics from '@/services/analytics';
import { buildObeSearchUrl } from '@/lib/buildObeSearchUrl';

export type WebsiteSearchFromProps = Omit<SearchFormProps, 'onSubmit'> & {
    fleetType?: string;
    fleetCategory?: string;
    layout?: string;
    country?: string;
    location?: string;
    hiddenFields?: SearchFormHiddenFields;
};

const WebsiteSearchForm: React.FC<WebsiteSearchFromProps> = ({ fleetType='campervan', fleetCategory, layout, country, location, hiddenFields }) => {
    const site = useSite();
    const onSubmit = useCallback(async (data: SearchFormValues) => {
        const { pickUpBranch } = searchFormValuesToData(data);
        analytics.plugins.bookingFunnel.bookingInitiated({
            fleetType: data.fleetType,
            pickUpLocation: data.pickUpBranch,
            pickUpDate: data.pickUpDate,
            dropOffLocation: data.dropOffBranch,
            dropOffDate: data.dropOffDate,
            coupon: data.promoCode,
            brand: 'jucy',
        });
        window.location.href = buildObeSearchUrl(data, pickUpBranch?.countryCode).toString();
        await sleep(15000);
    }, []);

    const defaultValues = {
        fleetType: fleetType,
        fleetCategory: fleetCategory,
        pickUpBranch: location,
    };
    let region: string | undefined = undefined;
    let variant: SearchFormVariants = 'large';
    if (layout === 'vertical') {
        variant = 'small';
    }
    if (country === 'Australia') {
        region = 'au';
    }
    if (country === 'New Zealand') {
        region = 'nz';
    }
    return (
        <SearchForm
            onSubmit={onSubmit}
            defaultValues={defaultValues}
            mode="all"
            options={{
                variant,
                region,
                sortRegion: site.urlSegment,
                hiddenFields: {
                    fleetType: typeof hiddenFields?.fleetType !== 'undefined' ? hiddenFields?.fleetType : Boolean((fleetType && fleetCategory) || (fleetType && location)),
                    ...hiddenFields,
                },
            }}
        />
    );
};

export default WebsiteSearchForm;
